// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-article-tsx": () => import("./../../../src/components/blog/article.tsx" /* webpackChunkName: "component---src-components-blog-article-tsx" */),
  "component---src-components-blog-author-index-tsx": () => import("./../../../src/components/blog/author/index.tsx" /* webpackChunkName: "component---src-components-blog-author-index-tsx" */),
  "component---src-components-blog-category-index-tsx": () => import("./../../../src/components/blog/category/index.tsx" /* webpackChunkName: "component---src-components-blog-category-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-branding-tsx": () => import("./../../../src/pages/branding.tsx" /* webpackChunkName: "component---src-pages-branding-tsx" */),
  "component---src-pages-change-impact-tsx": () => import("./../../../src/pages/change-impact.tsx" /* webpackChunkName: "component---src-pages-change-impact-tsx" */),
  "component---src-pages-cost-impact-tsx": () => import("./../../../src/pages/cost-impact.tsx" /* webpackChunkName: "component---src-pages-cost-impact-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-fully-integrated-tsx": () => import("./../../../src/pages/fully-integrated.tsx" /* webpackChunkName: "component---src-pages-fully-integrated-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integrations-tsx": () => import("./../../../src/pages/integrations.tsx" /* webpackChunkName: "component---src-pages-integrations-tsx" */),
  "component---src-pages-learn-about-tsx": () => import("./../../../src/pages/learn/about.tsx" /* webpackChunkName: "component---src-pages-learn-about-tsx" */),
  "component---src-pages-policies-tsx": () => import("./../../../src/pages/policies.tsx" /* webpackChunkName: "component---src-pages-policies-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-stakeholder-tsx": () => import("./../../../src/pages/stakeholder.tsx" /* webpackChunkName: "component---src-pages-stakeholder-tsx" */),
  "component---src-pages-use-cases-tsx": () => import("./../../../src/pages/use-cases.tsx" /* webpackChunkName: "component---src-pages-use-cases-tsx" */)
}

